



































































































































































































































import Vue from "vue";
import { Messages } from "@/models/enums/messages.enum";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { logisticServices } from "@/services/logistic.service";
import {
  DataWarehouseBranch,
  DateReturnNumber,
} from "@/models/interface/logistic.interface";
import { contactServices } from "@/services/contact.service";
import moment from "moment";
import { currencyFormat } from "@/validator/globalvalidator";
import printJs from "print-js";
import { debounceProcess } from "@/helpers/debounce";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
export default Vue.extend({
  name: "ReturnFromCustomer",
  data() {
    this.getBranch = debounceProcess(this.getBranch, 200);
    return {
      currentPage: 1 as number,
      downloadPrintParam: {
        companyName:
          this.$store.state.authStore.authData.companyName ||
          "PT. SATRIA PIRANTI PERKASA",
        branch: "ALL",
        returnNumber: "ALL",
        customerName: "ALL",
        dateFrom: "ALL",
        dateTo: "ALL",
      },
      limit: 10,
      page: 0,
      totalData: 0 as number,
      DEFAULT_DATE_FORMAT,
      isFormSubmitted: false as boolean,
      dataListReturnNumber: [] as DateReturnNumber[],
      dataListCustomer: [] as any[],
      dataBranch: [] as DataWarehouseBranch[],
      loadingDataListCustomer: false as boolean,
      loadingDataListReturnNumber: false as boolean,
      loading: false as boolean,
      loadingBranch: false as boolean,
      form: this.$form.createForm(this, { name: "returnfromcustomer" }),

      columnsTable: [
        {
          title: this.$t("lbl_return_number"),
          dataIndex: "returnNumber",
          key: "returnNumber",
          scopedSlots: { customRender: "isNull" },
          with: 200,
        },
        {
          title: this.$t("lbl_branch"),
          dataIndex: "branchName",
          key: "branchName",
          scopedSlots: { customRender: "isNull" },
          with: 150,
        },
        {
          title: this.$t("lbl_customer_name"),
          dataIndex: "customerName",
          key: "customername",
          scopedSlots: { customRender: "isNull" },
          with: 150,
        },
        {
          title: this.$t("lbl_date"),
          dataIndex: "returnDate",
          key: "date",
          scopedSlots: { customRender: "isDate" },
          with: 100,
        },
        {
          title: this.$t("lbl_total_return"),
          dataIndex: "returnTotal",
          key: "totalReturn",
          scopedSlots: { customRender: "isNull" },
          with: 150,
        },
        {
          title: this.$t("lbl_notes"),
          dataIndex: "notes",
          key: "notes",
          scopedSlots: { customRender: "isNull" },
          ellipsis: true,
          with: 150,
        },
        {
          title: this.$t("lbl_journal_number"),
          dataIndex: "journalNumber",
          key: "journalNumber",
          scopedSlots: { customRender: "clickColumn" },
          with: 150,
        },
      ] as any,
      dataList: [] as any[],
      formRules: {
        returnNumber: {
          label: "Return Number",
          name: "returnNumber",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "returnNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        dateFrom: {
          label: "Date From",
          name: "dateFrom",
          placeholder: "Insert Date From",
          decorator: [
            "dateFrom",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        dateTo: {
          label: "Date To",
          name: "dateTo",
          placeholder: "Insert Date To",
          decorator: [
            "dateTo",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        customerName: {
          label: "lbl_customer_name",
          name: "customerName",
          placeholder: "lbl_customer_name_placeholder",
          decorator: [
            "customerName",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        branch: {
          label: "Branch",
          name: "branch",
          placeholder: "Insert Branch",
          decorator: [
            "branch",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
      },
    };
  },
  methods: {
    handleClickColumn(record, objColumnNameValue) {
      this.$router.push("/generaljournal/journal/detail/" + record.journalId);
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    reponseViewTable(response) {
      this.$router.push(
        "/logistic/returnfromcustomer/edit/" + response.data.id
      );
    },
    findItems(page): void {
      this.form.validateFields((err, values) => {
        if (!err) {
          let arrSearch = [] as any;
          let searchParams = "" as string;
          Object.keys(values).forEach(key => {
            if (values[key]) {
              if (key === "returnNumber")
                arrSearch.push(`_AND_returnNumber~*${values.returnNumber}*`);
              if (key === "dateFrom")
                arrSearch.push(
                  `_AND_returnDate>=${moment(values.dateFrom)
                    .set("hour", 0)
                    .set("minute", 0)
                    .set("second", 0)
                    .format()}`
                );
              if (key === "dateTo")
                arrSearch.push(
                  `_AND_returnDate<=${moment(values.dateTo)
                    .set("hour", 23)
                    .set("minute", 59)
                    .set("second", 59)
                    .format()}`
                );
              if (key === "customerName")
                arrSearch.push(
                  `_AND_customer.firstName~*${values.customerName.replace(
                    /,/g,
                    '"'
                  )}*`
                );
              if (key === "branch")
                arrSearch.push(
                  `_AND_deliveryOrder.salesOrder.branch.secureId~${values.branch}`
                );
            }
          });
          if (arrSearch.length > 0) {
            searchParams = arrSearch.join().slice(5, arrSearch.join().length);
          } else {
            searchParams = "";
          }
          this.getData(searchParams.replace(/,/g, "").replace(/"/g, ","), page);
        }
      });
    },
    clearItems(): void {
      this.form.resetFields();
    },
    CreateNew(): void {
      this.$router.push("/logistic/returnfromcustomer/create");
    },

    download() {
      let params = {} as RequestQueryParamsModel;
      let searchParams = "" as string;
      let arrSearch = [] as any;
      this.form.validateFields((err, values) => {
        Object.keys(values).forEach(key => {
          if (values[key]) {
            if (key === "returnNumber") {
              arrSearch.push(`_AND_returnNumber~*${values.returnNumber}*`);
              this.downloadPrintParam[key] = values[key];
            }
            if (key === "dateFrom") {
              arrSearch.push(
                `_AND_returnDate>=${moment(values.dateFrom)
                  .set("hour", 0)
                  .set("minute", 0)
                  .set("second", 0)
                  .format()}`
              );
              this.downloadPrintParam[key] = moment(values.dateFrom)
                .set("hour", 0)
                .set("minute", 0)
                .set("second", 0)
                .format();
            }
            if (key === "dateTo") {
              arrSearch.push(
                `_AND_returnDate<=${moment(values.dateTo)
                  .set("hour", 23)
                  .set("minute", 59)
                  .set("second", 59)
                  .format()}`
              );
              this.downloadPrintParam[key] = moment(values.dateTo)
                .set("hour", 23)
                .set("minute", 59)
                .set("second", 59)
                .format();
            }
            if (key === "customerName") {
              arrSearch.push(
                `_AND_customer.secureId~*${
                  this.dataListCustomer.find(
                    item => item.firstName == values.customerName
                  ).id
                }*`
              );
              this.downloadPrintParam[key] = values[key];
            }
            if (key === "branch") {
              arrSearch.push(
                `_AND_deliveryOrder.salesOrder.branch.secureId~${values.branch}`
              );
              this.downloadPrintParam["branch"] = `${
                this.dataBranch.find(dataFind => dataFind.id === values[key])
                  ?.name
              }`;
            }
          }
        });
        if (arrSearch.length > 0) {
          searchParams = arrSearch.join().slice(5, arrSearch.join().length);
        } else {
          searchParams = "";
        }
        if (searchParams) {
          params.search = searchParams.replace(/,/g, "").replace(/"/g, ",");
        }
        params.params = `${this.downloadPrintParam.companyName},${this.downloadPrintParam.branch},${this.downloadPrintParam.returnNumber},${this.downloadPrintParam.customerName},${this.downloadPrintParam.dateFrom},${this.downloadPrintParam.dateTo}`;

        this.loadingDownload = true;
        logisticServices
          .downloadReportReturnFromCustomer(params)
          .then(dataBlob => {
            if (dataBlob) {
              const url = window.URL.createObjectURL(new Blob([dataBlob]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "report_receiveitems.xlsx"); //or any other extension
              document.body.appendChild(link);
              link.click();
            }
          })
          .finally(() => (this.loadingDownload = false));
      });
    },
    print() {
      let params = {} as RequestQueryParamsModel;
      let searchParams = "" as string;
      let arrSearch = [] as any;

      this.form.validateFields((err, values) => {
        Object.keys(values).forEach(key => {
          if (values[key]) {
            if (key === "returnNumber") {
              arrSearch.push(`_AND_returnNumber~*${values.returnNumber}*`);
              this.downloadPrintParam[key] = values[key];
            }
            if (key === "dateFrom") {
              arrSearch.push(
                `_AND_returnDate>=${moment(values.dateFrom)
                  .set("hour", 0)
                  .set("minute", 0)
                  .set("second", 0)
                  .format()}`
              );
              this.downloadPrintParam[key] = moment(values.dateFrom)
                .set("hour", 0)
                .set("minute", 0)
                .set("second", 0)
                .format();
            }
            if (key === "dateTo") {
              arrSearch.push(
                `_AND_returnDate<=${moment(values.dateTo)
                  .set("hour", 23)
                  .set("minute", 59)
                  .set("second", 59)
                  .format()}`
              );
              this.downloadPrintParam[key] = moment(values.dateTo)
                .set("hour", 23)
                .set("minute", 59)
                .set("second", 59)
                .format();
            }
            if (key === "customerName") {
              arrSearch.push(
                `_AND_customer.secureId~*${
                  this.dataListCustomer.find(
                    item => item.firstName == values.customerName
                  ).id
                }*`
              );
              this.downloadPrintParam[key] = values[key];
            }
            if (key === "branch") {
              arrSearch.push(
                `_AND_deliveryOrder.salesOrder.branch.secureId~${values.branch}`
              );
              this.downloadPrintParam["branch"] = `${
                this.dataBranch.find(dataFind => dataFind.id === values[key])
                  ?.name
              }`;
            }
          }
        });
        if (arrSearch.length > 0) {
          searchParams = arrSearch.join().slice(5, arrSearch.join().length);
        } else {
          searchParams = "";
        }
        if (searchParams) {
          params.search = searchParams.replace(/,/g, "").replace(/"/g, ",");
        }
        params.params = `${this.downloadPrintParam.companyName},${this.downloadPrintParam.branch},${this.downloadPrintParam.returnNumber},${this.downloadPrintParam.customerName},${this.downloadPrintParam.dateFrom},${this.downloadPrintParam.dateTo}`;

        this.loadingDownload = true;
        logisticServices
          .printReportReturnFromCustomer(params)
          .then(dataBlob => {
            if (dataBlob) {
              const url = window.URL.createObjectURL(new Blob([dataBlob]));
              printJs(url);
              // const url = window.URL.createObjectURL(new Blob([dataBlob]));
              // const link = document.createElement("a");
              // link.href = url;
              // link.setAttribute("download", "report_receiveitems.pdf"); //or any other extension
              // document.body.appendChild(link);
              // link.click();
            }
          })
          .finally(() => (this.loadingDownload = false));
      });
    },
    getBranch(valueSearch) {
      const params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
      };
      if (valueSearch)
        params.search = `name~*${valueSearch}*_OR_code~*${valueSearch}*_OR_address~*${valueSearch}`;
      this.loadingBranch = true;
      logisticServices
        .listWarehouseBranch(params, "")
        .then(response => {
          this.dataBranch = response.data;
        })
        .finally(() => (this.loadingBranch = false));
    },
    responsePageSizeChange(response: ResponsePagination) {
      this.limit = response.size;
      this.page = 0;
      this.currentPage = 1;
      this.findItems(true);
    },
    responseCurrentPageChange(response: ResponsePagination) {
      this.page = response.page - 1;
      this.currentPage = response.page;
      this.findItems(true);
    },
    getListContact(value) {
      let params = {
        limit: 10,
        page: 0,
        search: `customer~true_AND_active~true`,
      } as RequestQueryParamsModel;
      if (value) {
        params.search = `customer~true_AND_active~true_AND_firstName~*${value}*`;
      } else {
        params.search = `customer~true_AND_active~true`;
      }
      this.loadingDataListCustomer = true;
      contactServices
        .listContactData(params)
        .then((res: any) => {
          res.data.map((dataObject, index) => (dataObject.key = index));
          this.dataListCustomer = res.data;
        })
        .finally(() => (this.loadingDataListCustomer = false));
    },
    getData(value, page) {
      let params = {
        limit: this.limit,
        page: page ? this.page : 0,
        search: value,
        sorts: "createdDate:desc",
      } as RequestQueryParamsModel;
      this.loadingDataListReturnNumber = true;
      logisticServices
        .listReturnNumber(params)
        .then((res: any) => {
          res.data.map(
            (dataObject, index) => (
              (dataObject.key = index),
              (dataObject.returnDate = moment(dataObject.returnDate).format(
                DEFAULT_DATE_FORMAT
              )),
              (dataObject.returnTotal = currencyFormat(dataObject.returnTotal))
            )
          );
          this.dataList = res.data;
          this.totalData = res.totalElements;
        })
        .finally(() => (this.loadingDataListReturnNumber = false));
    },
    getListOfReturnNumber(query, value) {
      let params = {
        limit: this.limit,
        page: this.page,
      } as RequestQueryParamsModel;
      if (query && value) params.search = query;
      this.loadingDataListReturnNumber = true;
      logisticServices
        .listReturnNumber(params)
        .then((res: any) => {
          res.data.map((dataObject, index) => (dataObject.key = index));
          this.dataListReturnNumber = res.data;
        })
        .finally(() => (this.loadingDataListReturnNumber = false));
    },
  },
  mounted() {
    this.getListOfReturnNumber("", "");
    this.getListContact("");
  },
  created() {
    this.getBranch("");
    {
      this.columnsTable.push({
        title: this.$root.$t("lbl_action").toString(),
        dataIndex: "operation",
        key: "operation",
        scopedSlots: { customRender: "operation" },
        button: ["view"],
        width: 120,
        align: "center",
        fixed: "right",
      });
    }
  },
});
